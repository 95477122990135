<template>
  <div class="admin-quick-messages">
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings/templates')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Quick Incident Responses
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search quick incident responses"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadQuickMessages"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createQuickIncidentResponse"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>

    <a-alert style="margin-bottom: 25px"
     message="Quick Incident Responses help you create incidents faster by automatically filling in the required fields.">
    </a-alert>

    <!-- Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No results -->
      <a-alert
        v-if="!isLoading && quickIncidentResponsesToShow.length == 0"
        type="info"
        message="No quick incident responses to show"
      >
      </a-alert>
      <!-- / No results -->

      <!-- Results -->
      <div
        class="quick-messages-list"
        v-if="!isLoading && quickIncidentResponsesToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="quickIncidentResponse in quickIncidentResponsesToShow"
            :key="quickIncidentResponse.id"
          >
            <quick-message-list-item
              :quick-message="quickIncidentResponse"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToQuickIncidentResponse(quickIncidentResponse)"
            >
            </quick-message-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Results -->
    </div>
    <!-- / Loaded- -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import quickMessages from "../../../api/quick-messages";
import QuickMessageListItem from "./QuickMessages/QuickMessageListItem.vue";
const _ = require('lodash');

export default {
  components: { QuickMessageListItem },

  data() {
    return {
      isLoading: false,
      quickIncidentResponses: [],
      searchQuery: "",
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    quickIncidentResponsesToShow() {
      let filtered = _.filter(this.quickIncidentResponses, (quickIncidentResponses) => {
        if (this.searchQuery.trim().length) {
          return quickIncidentResponses.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      return _.orderBy(filtered, "displayName");
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadQuickIncidentResponses();
  },

  methods: {
    loadQuickIncidentResponses() {
      let vm = this;
      vm.isLoading = true;
      vm.quickIncidentResponses = [];
      quickMessages
        .getQuickMessages(vm.tenantId, vm.selectedOrganisation.id, true, 'NewIncident')
        .then((r) => {
          vm.isLoading = false;
          vm.quickIncidentResponses = r.data;
        })
        .catch((e) => {
          vm.isLoading = false;
          vm.$message.error("Error loading quick incident responses");
          console.log(e);
        });
    },

    createQuickIncidentResponse() {
      this.$router.push("/admin/settings/quick-incident-responses/create");
    },

    navigateToQuickIncidentResponse(quickIncidentResponse) {
      this.$router.push("/admin/settings/quick-incident-responses/" + quickIncidentResponse.id + "/edit");
    },
  },
};
</script>

<style scoped lang="scss">
.admin-quick-messages {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  padding-bottom: 50px;

  .button-margin-left {
    margin-left: 15px;
  }

  .quick-message-list-item {
    margin-bottom: 15px;
  }
}
</style>